import { Box, Grid, Heading } from '@grupoboticario/flora-react';
import { Divider } from '@/shared';
import { changedFieldTextFormatter, dateFormatter, mapToStringLhsRhsData } from '@/utils';
import type { ChangeHistoryDataType } from '@/types';
import { ChangeHistoryField } from './components/change-history-field';
import { useChangeHistoryStore } from '@/store';

const ChangeHistoryData = () => {
  const data = useChangeHistoryStore((state) => state.data);

  const historyData: ChangeHistoryDataType[] =
    !!data &&
    data?.map((item) => ({
      _id: item._id,
      fields: [
        {
          gridColumn: '4',
          fieldTitle: 'Usuário',
          fieldText: item.user.name,
        },
        {
          gridColumn: '4',
          fieldTitle: 'Central de Serviços',
          fieldText: item.csName,
        },
        {
          gridColumn: '2',
          fieldTitle: 'Adere às Regras de Ouro?',
          fieldText: item.hasGoldenRule,
        },
        {
          gridColumn: '2',
          fieldTitle: 'Data da alteração',
          fieldText: dateFormatter(item.updatedAt),
        },
        {
          gridColumn: '12',
          fieldTitle: 'Campo alterado',
          fieldText: changedFieldTextFormatter(item.fields.path),
        },
        {
          gridColumn: '12',
          fieldTitle: 'Dado antigo',
          fieldText: mapToStringLhsRhsData(item.fields.lhs) ?? 'Informação não preenchida',
          kind: item.fields.kind === 'N' ? item.fields.kind : undefined,
        },
        {
          gridColumn: '12',
          fieldTitle: 'Dado novo',
          fieldText: mapToStringLhsRhsData(item.fields.rhs) ?? 'Dado Deletado',
          kind: item.fields.kind === 'D' ? item.fields.kind : undefined,
        },
      ],
    }));

  return (
    <Box css={{ padding: '0 $4', marginBottom: '$22' }}>
      <Heading
        as='h2'
        css={{ marginBottom: '$4', fontSize: '$lg', lineHeight: '$normal', fontWeight: '$medium' }}
      >
        Registro de logs
      </Heading>

      {historyData.map((item, index) => (
        <Grid
          gap='$1'
          templateColumns='repeat(12, 1fr)'
          key={item._id}
          data-testid='change-history-data-id'
        >
          <ChangeHistoryField item={item.fields} id={item._id} />
          {index !== data.length - 1 && <Divider css={{ gridColumn: 'span 12', margin: '$4 0' }} />}
        </Grid>
      ))}
    </Box>
  );
};

export { ChangeHistoryData };
