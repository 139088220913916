import { FloraProvider } from '@grupoboticario/flora-react';
import { App } from './App';

export default function Root() {
  return (
    <FloraProvider>
      <App />
    </FloraProvider>
  );
}
