const franchiseDict: Record<string, string> = {
  franchise: 'Dados Cadastrais',
  contacts: 'Contatos',
  phones: 'Telefones',
  email: 'E-mail',
  cpCode: 'Código do CP',
  address: 'Dados da Central de Serviços',
  city: 'Cidade',
  csCode: 'Código da CS',
  referencePoint: 'Ponto de referência',
  number: 'Nº',
  landmark: 'Bairro',
  state: 'Estado',
  street: 'Endereço',
  zipCode: 'CEP',
  name: 'Nome do franqueado',
  owner: 'Responsável operacional',
  whatsapp: 'Possui WhatsApp?',
  phone: 'Número',
  primary: 'Telefone principal?',
};

const documentationDict: Record<string, string> = {
  documentation: 'Documentação',
  hasAdditionalDocumentation: 'Documentação',
  documentationNote: 'Documentos',
};

const businessHoursDict: Record<string, string> = {
  businessHours: 'Horário de Funcionamento',
  days: 'Dias de atendimento',
  day: 'Dias selecionados',
  hours: 'Intervalos de horários',
  opensAt: 'Horário de abertura',
  closesAt: 'Horário de fechamento',
  holidays: 'Feriados',
  openOnHolidays: 'Abre aos feriados?',
  closed: 'Fechado',
  Mon: 'Segunda-feira',
  Tue: 'Terça-feira',
  Wed: 'Quarta-feira',
  Thu: 'Quinta-feira',
  Fri: 'Sexta-feira',
  Sat: 'Sábado',
  Sun: 'Domingo',
};

const orderIncrementDict: Record<string, string> = {
  orderIncrement: 'Incremento de Pedido',
  allowIncrement: 'Permite adicionar produtos, além do kit inicial?',
  hasRequiredQuantity: 'Obrigatório incrementar?',
  requiredQuantity: 'Obrigatório incrementar? - Quantidade obrigatória',
  hasMaximumValue: 'Valor máximo?',
  maximumValue: 'Valor máximo? > Valor',
  hasMinimumValue: 'Valor mínimo?',
  minimumValue: 'Valor mínimo? > Valor',
  hasCategories: 'Categoria de produtos específica?',
  categories: 'Categoria de produtos específica? > Categorias',
};

const starterPackDict: Record<string, string> = {
  starterPack: 'Inicia com Produtos',
  allowStarterKit: 'Permite iniciar com produtos?',
  onlyIfStarterKitIsUnavailable: 'Somente se o kit inicial estiver indisponível',
  hasStarterKitInstruction: 'Adicionar orientações se o kit inicial não estiver disponível?',
  starterKitInstruction:
    'Adicionar orientações se o kit inicial não estiver disponível? > Instruções',
};

const pickupStoreDict: Record<string, string> = {
  pickUpStore: 'Retirada',
  allowPickUpStore: 'Autoriza retirada?',
  pickUpStoreInfo: 'Autoriza retirada? > Condições',
  hasIdentificationRequirements: 'Exige documento para retirada?',
  identificationRequirements: 'Exige documento para retirada? > Requisitos',
  hasProofOfAddress: 'Há especificações sobre os comprovantes de endereço aceitos?',
  proofOfAddress: 'Há especificações sobre os comprovantes de endereço aceitos? > Comprovantes',
  proofOfAddressSpecifications:
    'Há especificações sobre os comprovantes de endereço aceitos? > Especificações',
  hasScheduleForPickUp: 'Exige agendamento para retirada?',
  scheduleForPickUpNote: 'Exige agendamento para retirada? > Orientações',
  hasThirdPartySupport: 'Autoriza a retirada por terceiros?',
  thirdPartySupportInfo: 'Autoriza a retirada por terceiros? > Orientações',
  hasMinimumPickUpPeriod: 'Prazo mínimo para retirada?',
  minimumPickUpPeriodQty: 'Prazo mínimo para retirada? > Quantidade',
  minimumPickUpPeriodInfo: 'Prazo mínimo para retirada? > Período',
  hasMaximumPickUpPeriod: 'Prazo máximo para retirada?',
  maximumPickUpPeriodQty: 'Prazo máximo para retirada? > Quantidade',
  maximumPickUpPeriodInfo: 'Prazo máximo para retirada? > Quantidade',
};

const deliveryDict: Record<string, string> = {
  delivery: 'Entrega',
  hasDelivery: 'Realiza entrega?',
  deliveryCost: 'Valor do frete',
  hasFreeDelivery: 'Frete gratuito',
  hasFreeDeliveryOnFirstOrder: 'Frete gratuito na primeira compra',
  hasSpecialConditions: 'Condições especiais por valor da compra?',
  specialConditions: 'Condição especial',
  minimumPurchaseValue: 'A partir de',
  hasFreeDeliveryDuringPandemic: 'Frete gratuito durante a pandemia?',
  hasNotAllowedDeliveryRegions: 'Entrega bloqueada para alguma região?',
  notAllowedDeliveryRegions: 'Regiões',
  allowDeliveryToThirdParties: 'Entrega para terceiros?',
  deliveryToThirdPartiesRules: 'Entrega para terceiros? > Requisitos',
};

const lockAndLimitDict: Record<string, string> = {
  lockAndLimit: 'Bloqueio e limite',
  hasBlockAfterFirstOrder: 'Bloquear cadastro após primeiro pedido?',
  unlockAfterPayingFirstOrder: 'Desbloqueio após pagamento do primeiro pedido',
  allowToInformAvailableLimit: 'Permite informar limite de crédito disponível?',
};

const paymentMethodsDict: Record<string, string> = {
  paymentMethods: 'Formas de pagamento',
  bankSlipPayment: 'Boleto',
  cashPayment: 'Dinheiro',
  creditCardPayment: 'Cartão de crédito',
  debitCardPayment: 'Cartão de débito',
  depositAndTransferPayment: 'Depósito e transferência',
  onlineCardPayment: 'Cartão on-line',
  pixPayment: 'Pix',
  hasMinimumInstallmentValue: 'Valor mínimo de parcela',
  minimumInstallmentValue: 'Valor',
  hasInstallmentOptions: 'Outras opções de parcelamento?',
  installmentOptions: 'Opção de parcelamento',
  maximumInstallments: 'Máximo de parcelas',
  condition: 'Condição',
  value: 'Valor',
  isEnabled: 'Aceita forma de pagamento?',
  consumesAvailableCredit: 'Consome o crédito disponível?',
  hasThirtyDaysPayment: '30 dias',
  hasPaymentCash: 'A vista',
  paymentLocations: 'Condições gerais',
  requestsPaymentVoucher: 'Necessário solicitar o envio do comprovante?',
  paymentVoucherWhatsapp: 'WhatsApp para envio de comprovante',
  cpfCnpj: 'CNPJ/CPF',
  bank: 'Banco',
  bankBranch: 'Agência',
  accountNumber: 'Conta',
  accountType: 'Tipo de conta',
  sendTo: 'Envio do comprovante - Opções de envio',
  specifications: 'Envio do comprovante - Especificações',
  paymentVoucherEmail: 'E-mail para envio de comprovante',
  keyType: 'Tipo de chave',
  pixKeyValue: 'Chave pix',
  'purchases-until': 'Compras até',
  'purchases-from': 'Compras a partir de',
  independent: 'Independente do valor',
  random: 'Chave aleatória',
  'phone-number': 'Número de telefone',
};

const commonTermsDict: Record<string, string> = {
  hasSpecificProducts: 'Produtos específicos?',
  specificProducts: 'Produtos específicos? > Produtos',
  note: 'Observação',
};

export const changedFieldTextFormatterDict: Record<string, string> = {
  ...franchiseDict,
  ...documentationDict,
  ...businessHoursDict,
  ...orderIncrementDict,
  ...starterPackDict,
  ...pickupStoreDict,
  ...deliveryDict,
  ...lockAndLimitDict,
  ...paymentMethodsDict,
  ...commonTermsDict,
};
