import { Box, Flex, Input } from '@grupoboticario/flora-react';
import { useForm } from 'react-hook-form';
import { useChangeHistoryStore } from '@/store';
import { SearchBarIcon } from '@/components';

interface SearchBarFormValues {
  serviceCenterId: string;
}

export const SearchBar = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { serviceCenterId: '' },
    mode: 'onChange',
  });

  const fetchData = useChangeHistoryStore((state) => state.fetchByCs);

  const onSubmit = (data: SearchBarFormValues) => {
    fetchData(data.serviceCenterId);
  };

  return (
    <Flex
      align='center'
      justify='center'
      css={{
        background: '$backgroundPrimary',
        marginBottom: '$6',
        padding: '$4 0',
      }}
    >
      <Box
        css={{
          width: '95%',
          '& > div': {
            minHeight: 'auto',
          },
        }}
        as='form'
        data-testid='search-service-center-form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          data-testid='search-service-center-input'
          id='search-service-center-filter'
          labelText='Buscar:'
          placeholder='Informe o código da Central de Serviços (CS)'
          optionalText=''
          defaultValue=''
          invalidText={errors?.serviceCenterId?.message}
          invalid={!!errors?.serviceCenterId}
          rightIcon={<SearchBarIcon />}
          {...register('serviceCenterId')}
        />
      </Box>
    </Flex>
  );
};
