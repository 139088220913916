import React from 'react';
import { Box, Flex } from '@grupoboticario/flora-react';

interface InfoWrapperProps {
  gridColumn?: string;
  children: React.ReactNode;
}

const LogFieldWrapper = ({ children, gridColumn }: InfoWrapperProps): JSX.Element => (
  <Box
    css={{
      gridColumn: `span ${gridColumn}`,
      backgroundColor: '$backgroundPrimary',
      borderRadius: '$medium',
      padding: '$3 $8',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.12)',
    }}
  >
    <Flex gap='$1' css={{ height: '100%' }}>
      {children}
    </Flex>
  </Box>
);

export { LogFieldWrapper };
