import { useChangeHistoryStore } from '@/store';
import { dateFormatter } from '@/utils';
import { Flex, Pagination, Text } from '@grupoboticario/flora-react';

export const Footer = () => {
  const fetchData = useChangeHistoryStore((state) => state.fetchByPage);
  const page = useChangeHistoryStore((state) => state.page);
  const limit = useChangeHistoryStore((state) => state.limit);
  const total = useChangeHistoryStore((state) => state.total);

  const endTotalResults = (page + 1) * limit;
  const initialTotalResults = endTotalResults - (limit - 1);

  return (
    <Flex
      css={{
        minWidth: '100%',
        padding: '$4',
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'rgba(245, 245, 245, 1)',
      }}
      align='center'
      justify='space-between'
    >
      <Text color='$nonInteractivePredominant' size='bodyMediumShort' data-testid='page-results'>
        Exibindo {initialTotalResults} - {endTotalResults} de {total} resultados
      </Text>
      <Pagination itemsPerPage={limit} onChange={fetchData} page={page} totalRecords={total} />
      <Text color='$nonInteractivePredominant' size='bodyMediumShort'>
        Última atualização: {dateFormatter(new Date().toString())}
      </Text>
    </Flex>
  );
};
