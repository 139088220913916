import { fetcher } from '@/api';
import { GetChangeHistoryResponse } from '@/types';

export interface GetChangeHistoryRequest {
  page?: number;
  limit?: number;
  filter?: string;
}

export const getChangeHistory = (
  queryParams?: GetChangeHistoryRequest
): Promise<GetChangeHistoryResponse> => {
  return fetcher.get<GetChangeHistoryResponse>('service-centers/change-history', {
    searchParams: queryParams ? { ...queryParams } : undefined,
  });
};
