import { toLocaleDateTime } from '@grupoboticario/vdi-mfe-utils';

export const dateFormatter = (date: string | undefined): string => {
  if (!date) return '- - -';
  const updatedAt = toLocaleDateTime(date, {
    format: (date: string) => date.replace(',', ''),
    locale: 'pt-BR',
    second: undefined,
    timeZone: 'America/Sao_Paulo',
  });

  return updatedAt;
};
