import { KindField } from '@/types';
import { capitalizeText } from '@/utils';
import { Box, Flex, Text, CSS } from '@grupoboticario/flora-react';

type TextFieldType = string | string[] | number[] | boolean | undefined;
interface LogFieldProps {
  text: TextFieldType;
  title: string;
  kind?: KindField;
}

const textFormatter = (text: TextFieldType) => {
  if (!text) {
    return '- - -';
  }

  return text instanceof Array
    ? text.map((textToFormat) => `${capitalizeText(String(textToFormat))} `)
    : capitalizeText(String(text));
};

const LogField = ({ text, title, kind }: LogFieldProps) => {
  const formattedTextToDisplay = textFormatter(text);
  const isColorKindAddedOrDeleted: CSS['color'] = kind
    ? '$linkDefault'
    : '$nonInteractivePredominant';

  return (
    <Box
      css={{
        width: '100%',
      }}
    >
      <Flex direction={'column'}>
        <Text
          css={{ fontWeight: '$medium', color: '$nonInteractivePredominant' }}
          size='bodyMediumStandard'
          data-testid='log-field-title'
        >
          {title}
        </Text>

        {text instanceof Array ? (
          text.map((textToFormat) => (
            <Text
              key={textToFormat}
              css={{ fontWeight: '$medium', color: isColorKindAddedOrDeleted }}
              data-testid='log-field-text'
            >
              {textFormatter(textToFormat as string)}
            </Text>
          ))
        ) : (
          <Text
            css={{ fontWeight: '$medium', color: isColorKindAddedOrDeleted }}
            data-testid='log-field-text'
          >
            {formattedTextToDisplay}
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export { LogField };
