import { LogFieldWrapper, LogField } from '@/shared';
import { ChangeHistoryDataField } from '@/types';

interface ChangeHistoryFieldProps {
  item: ChangeHistoryDataField[];
  id: string;
}
const ChangeHistoryField = ({ item, id }: ChangeHistoryFieldProps) => (
  <>
    {item.map((field) => (
      <LogFieldWrapper gridColumn={field.gridColumn} key={`${id}-${field.fieldTitle}`}>
        <LogField title={field.fieldTitle} text={field.fieldText} kind={field.kind} />
      </LogFieldWrapper>
    ))}
  </>
);

export { ChangeHistoryField };
